import React, { useRef, useEffect, useState } from 'react'
import "./CloudinaryVideo.scss"
import 'cloudinary-video-player'
import './cld-video-player.min.css'

interface CloudinaryVideoProps {
    cloudName: string
    publicId: string
    image: {
        data: {
            attributes: {
                url: string
            }
        }
    }
}

const CloudinaryVideo = (props: any) => {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

    useEffect(() => {
        if (!window.cloudinary || !videoRef.current) {
            console.error('Cloudinary player not loaded or video ref is null')
            return
        }
        // @ts-ignore
        const cld = window.cloudinary?.Cloudinary.new({ cloud_name: props.cloudName })
        const player = cld.videoPlayer(videoRef.current, {
            controls: isTouchDevice,
            autoplay: !isTouchDevice,
            playsinline: true,
            preload: 'auto',
            muted: true,
            fluid: true,
            loop: true
        })
        player.source(props.publicId, {
            transformation: { quality: 'auto', format: 'auto' },
        })

        return () => {
            player?.dispose()
        }
    }, [props.cloudName, props.publicId])

    return (
        <video
            ref={videoRef}
            className="cld-video-player cld-fluid"
            playsInline
            controls={isTouchDevice}
            loop
        />
    )
}

export default CloudinaryVideo