import { useTranslation } from "react-i18next"
import './HomepageIntro.scss'
import { HomepageProps } from "../Interfaces"
import RichText from "../RichText/RichText"
import CloudinaryVideo from "../CloudinaryVideo/CloudinaryVideo"

interface IHomepageIntroProps {
    homepage: HomepageProps
}

const HomepageIntro = (props: IHomepageIntroProps) => {
    const { t } = useTranslation();

    const displayVideo: boolean = Boolean(props.homepage.attributes.videoCloudName && props.homepage.attributes.videoPublicId)

    return (
        <div className={`homepage-intro--wrapper`}>
            <div className={`homepage-intro--content`}>
                <h1 className={`homepageIntro--heading`}><RichText content={props.homepage.attributes.heading} /></h1>
                <RichText content={props.homepage.attributes.text} />
            </div>
            {displayVideo && (
                <div className={`homepage-intro--video`}>
                    <CloudinaryVideo
                        cloudName={props.homepage.attributes.videoCloudName}
                        publicId={props.homepage.attributes.videoPublicId}
                        image={props.homepage.attributes.image}
                    />
                </div>
            )}
            {!displayVideo && (
                <div className={`homepage-intro--image`}>
                    <img src={props.homepage.attributes.image.data.attributes.url} alt={`Měsíc školních zahrad`} />
                </div>
            )}
        </div>
    )
}

export default HomepageIntro;