import ReactMarkdown from "react-markdown";
import './RichText.scss'
import clsx from 'clsx'

interface RichTextProps {
    content: string
    className?: string
}

const RichText = (props: RichTextProps) => {
    return (
        <div className={clsx(`rich-text`, props.className)}>
            <ReactMarkdown
                components={{
                    a: ({ children, href }) => (
                        <a
                            href={href}
                            target={
                                href && href.includes('https') && !href.includes('mesicskolnichzahrad') ? '_blank' : '_self'
                            }
                            rel="noreferrer"
                        >
                            {children}
                        </a>
                    ),
                }}
            >
                {props.content}
            </ReactMarkdown>
        </div>
    )
}

export default RichText