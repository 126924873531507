import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.scss";
import HeaderModule from "./components/HeaderModule/HeaderModule";
import FooterModule from "./components/FooterModule/FooterModule";
import ActivitiesPage from "./Pages/ActivitiesPage";
import ActivityPage from "./Pages/ActivityPage";
import ArchivePage from "./Pages/ArchivePage";
import ContactsPage from "./Pages/ContactsPage";
import AboutUsPage from "./Pages/AboutUsPage";
import AboutProjectPage from "./Pages/AboutProjectPage";
import ProjectPage from "./Pages/ProjectPage";
import RulesPage from "./Pages/RulesPage";
import { createTheme, ThemeProvider } from "@mui/material";
import MapScript from "./components/MapScript/MapScript";
import ParentsPage from "./Pages/ParentsPage";

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: "Nunito"
  },
});

const App: React.FC = () => {

  /*
  useEffect(() => {
    if (process.env.PUBLIC_GTM_ID) {
      const TagManager = require('react-gtm-module')
      TagManager.initialize({
        gtmId: process.env.PUBLIC_GTM_ID || ''
      })
    }
  }, []);
  */

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="DataSheetsApp">
          <Router>
            <HeaderModule />
            <div className="app-page-content">
              <Routes>
                <Route path="/" element={<ActivitiesPage />} />
                <Route path="/detail" element={<ActivityPage />} />
                <Route path="/archive" element={<ArchivePage />} />
                <Route path="/contacts" element={<ContactsPage />} />
                <Route path="/aboutUs" element={<AboutUsPage />} />
                <Route path="/aboutProject" element={<AboutProjectPage />} />
                <Route path="/project" element={<ProjectPage />} />
                <Route path="/rules" element={<RulesPage />} />
                <Route path="/why" element={<ParentsPage />} />
              </Routes>
            </div>
            <MapScript />
            <FooterModule />
          </Router>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
