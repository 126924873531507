interface AppSettings {
    cmsApiUrl: string;
    facebookUrl: string;
    newsletter:string
    nadacePromenyUrl:string
    ucimeSeVenkuUrl:string
}

export const appSettings: AppSettings = {
    cmsApiUrl: process.env.REACT_APP_API_URL ?? '',
    facebookUrl: "https://www.facebook.com/groups/151143705522166",
    newsletter: "https://us12.list-manage.com/subscribe?u=b811ff0ff0dafbe51696e66ec&id=e7392e1076",
    nadacePromenyUrl: "https://www.nadace-promeny.cz/",
    ucimeSeVenkuUrl: "https://ucimesevenku.cz/"
}

