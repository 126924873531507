import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./HeaderModule.scss";
import { useDevice } from "src/hooks/useDevice";
import { appSettings } from "src/config";
import clsx from "clsx";

export const getLocale = () => {
  return "cs";
};

const ExternalHeaderModule = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  const { t } = useTranslation();
  const device = useDevice();

  const showHamburgerMenu = () => {
    setShowMenu(true);
  };

  const hideHamburgerMenu = () => {
    setShowMenu(false);
  };

  const showTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
    dispatchEvent(
      new CustomEvent("ChangeActiveTabMobile", {
        detail: { activeTabIndex: tabIndex },
      })
    );
    setShowMenu(false);
  };

  const isDesktop =
    device.isDesktop || device.isLargeDesktop || device.isExtraLargeDesktop;

  return (
    <div className="HeaderModule">
      <nav className="header">
        <div className="header-row">
          <div className="logo-breadcrumbs-wrapper">
            <a className="logo-wrapper" href={"/"}>
              <span className="header-text">{t("siteName")}</span>
            </a>
          </div>

          {isDesktop && (
              <div className="header-center-part">

                <a href={`/`} className={clsx(window.location.pathname === '/' ? 'active' : '')}>{t("activityTab")}</a>
                <a href={`/project`}
                   className={clsx(window.location.pathname === '/project' ? 'active' : '')}>{t("whyGetInvolved")}</a>
                <a href={`/rules`}
                   className={clsx(window.location.pathname === '/rules' ? 'active' : '')}>{t("gameRules")}</a>
                <a href={`/why`}
                   className={clsx(window.location.pathname === '/why' ? 'active' : '')}>{t("parentsMenu")}</a>

              </div>
          )}

          <div className="header-right-part">
            {isDesktop && (
                <a href={`/contacts`} className="header-button" >{t("contacts")}</a>
            )}

            <div className="header-menu">
              {!isDesktop && !showMenu && (
                <svg
                  className="hamburger-menu-icon"
                  viewBox="0 0 100 80"
                  width="30"
                  height="30"
                  onClick={showHamburgerMenu}
                >
                  <rect
                    className="hamburger-menu-icon-item"
                    width="100"
                    height="12"
                  ></rect>
                  <rect
                    className="hamburger-menu-icon-item"
                    y="30"
                    width="100"
                    height="12"
                  ></rect>
                  <rect
                    className="hamburger-menu-icon-item"
                    y="60"
                    width="100"
                    height="12"
                  ></rect>
                </svg>
              )}

              {!isDesktop && showMenu && (
                <div
                  className="hamburger-menu-close"
                  onClick={hideHamburgerMenu}
                />
              )}

              {!isDesktop && showMenu && (
                  <div className="hamburger-menu">
                    <a href={`/`}
                       className={`hamburger-menu-item`}
                    >
                      <span>{t("activityTab")}</span>
                    </a>
                    <a href={`/project`}
                       className={`hamburger-menu-item`}
                    >
                      <span>{t("whyGetInvolved")}</span>
                    </a>
                    <a href={`/rules`}
                       className={`hamburger-menu-item`}
                    >
                      <span>{t("gameRules")}</span>
                    </a>
                    <a href={`/why`}
                       className={`hamburger-menu-item`}
                    >
                      <span>{t("parents")}</span>
                    </a>
                    <a href={`/contacts`}
                       className={`hamburger-menu-item`}
                    >
                      <span>{t("contacts")}</span>
                    </a>
                  </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default ExternalHeaderModule;
